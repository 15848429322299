<template>
  <div class="company-page" style="width: 60%;">
    <el-form :model="companyForm" label-width="200px">
      <el-form-item label="Copyright">
        <el-input v-model="companyForm.copyright" type="text"/>
      </el-form-item>
      <el-form-item label="Designed by">
        <el-input v-model="companyForm.designedBy" type="text"/>
      </el-form-item>
      <el-form-item label="公司名称（版权所有）">
        <el-input v-model="companyForm.companyName" type="text"/>
      </el-form-item>
      <el-form-item label="地址">
        <el-input v-model="companyForm.address" type="text"/>
      </el-form-item>
      <el-form-item label="电话">
        <el-input v-model="companyForm.telephone" type="text"/>
      </el-form-item>
      <el-form-item label="QQ">
        <el-input v-model="companyForm.qq" type="text"/>
      </el-form-item>
      <el-form-item label="微信">
        <el-input v-model="companyForm.wx" type="text"/>
      </el-form-item>
      <el-form-item label="邮箱">
        <el-input v-model="companyForm.email" type="text"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSubmit">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    data() {
      return {
        companyForm: {
          companyName: '',
          copyright: '',
          designedBy: '',
          address: '',
          telephone: '',
          qq: '',
          wx: '',
          email: ''
        }
      }
    },
    mounted() {
      this.getCompanyInfo()
    },
    
    methods: {
      getCompanyInfo() {
        axios.get('/api/company').then(data => {
          this.companyForm = {...data.data}
        })
      },
      handleSubmit() {
        axios.put('/api/company', this.companyForm).then(data => {
          if (data.data === 'success') {
            this.$notify.success('更新成功')
          } else {
            this.$notify.error('更新失败')
          }
        })
      }
    }
  }
</script>
